import React from "react";
import { Main } from "./components/Main";
import { Helmet } from "react-helmet-async";

export const PriceList = () => {
  return (
    <>
      <Helmet>
        <title>Cennik - Fizjoterapeuta w Warszawie</title>
        <meta name="description" content="Sprawdź nasz cennik usług fizjoterapeutycznych. Oferujemy szeroki zakres zabiegów rehabilitacyjnych w atrakcyjnych cenach." />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href="https://www.fizjocezar.pl/cennik" />
      </Helmet>
      <Main />
    </>
  );
};
