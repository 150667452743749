import React from "react";
import { Main } from "./components/Main";
import { Helmet } from "react-helmet-async";

export const OfferSeventh = () => {
  return (
    <>
      <Helmet>
        <title>Ćwiczenia Indywidualne - Fizjoterapeuta w Warszawie</title>
        <meta name="description" content="Dowiedz się więcej o ćwiczeniach indywidualnych. Oferujemy profesjonalne konsultacje i programy ćwiczeń dostosowane do indywidualnych potrzeb klientów. Skontaktuj się z nami, aby umówić się na konsultację." />
        <link rel="canonical" href="https://www.fizjocezar.pl/ćwiczenia-indywidualne" />
      </Helmet>
      <Main />
    </>
  );
};
