import React, { useState } from "react";
import QuestionMain from "./../../../styles/layouts/Questions/components/Main.module.css";
import { Link } from "react-router-dom";
export const Main = () => {
  const [selectedQuestion, setSelectedQuestion] = useState("");

  const handleQuestionClick = (question: React.SetStateAction<string>) => {
    if (selectedQuestion === question) {
      setSelectedQuestion("");
    } else {
      setSelectedQuestion(question);
    }
  };
  return (
    <>
      <div className={QuestionMain.containerMain}>
        <div className={QuestionMain.aboutSection}>
          <h1>Pytania</h1>
        </div>
        <div className={QuestionMain.homeLink}>
          <Link to="/">Strona główna</Link>
          <Link to="/faq">Pytania</Link>
        </div>
      </div>
      <main className={QuestionMain.mainSection}>
        <div>
          <div
            className={QuestionMain.question}
            onClick={() => handleQuestionClick("Jak wygląda przebieg wizyty?")}
          >
            {selectedQuestion === "Jak wygląda przebieg wizyty?" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="16"
                width="14"
                viewBox="0 0 448 512"
              >
                <path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="16"
                width="14"
                viewBox="0 0 448 512"
              >
                <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
              </svg>
            )}

            <h4>Jak wygląda przebieg wizyty?</h4>
          </div>
          <div
            className={`${QuestionMain.answer} ${selectedQuestion === "Jak wygląda przebieg wizyty?"
              ? QuestionMain.show
              : ""
              }`}
          >
            <p>
              Pierwszą wizytę charakteryzuje schemat WYWIAD — BADANIE — TERAPIA.
            </p>
            <br />
            <p>
              Każda kolejna wizyta to indywidualnie dostosowana terapia: terapia manualna/ ćwiczenia/ masaże.
              Plan działania ustalamy na pierwszej wizycie i modyfikujemy go w trakcie trwania terapii w zależności od potrzeby i zawsze w porozumieniu z pacjentem.
            </p>
          </div>
        </div>

        <div>
          <div
            className={QuestionMain.question}
            onClick={() => handleQuestionClick("Jakie metody płatności?")}
          >
            {selectedQuestion === "Jakie metody płatności?" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="16"
                width="14"
                viewBox="0 0 448 512"
              >
                <path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="16"
                width="14"
                viewBox="0 0 448 512"
              >
                <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
              </svg>
            )}
            <h4>Jakie metody płatności?</h4>
          </div>
          <div
            className={`${QuestionMain.answer} ${selectedQuestion === "Jakie metody płatności?"
              ? QuestionMain.show
              : ""
              }`}
          >
            <p>Przyjmuję płatność gotówką lub przelewem na konto.</p>
          </div>
        </div>
        <div>
          <div
            className={QuestionMain.question}
            onClick={() => handleQuestionClick("Czy dostanę fakturę?")}
          >
            {selectedQuestion === "Czy dostanę fakturę?" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="16"
                width="14"
                viewBox="0 0 448 512"
              >
                <path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="16"
                width="14"
                viewBox="0 0 448 512"
              >
                <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
              </svg>
            )}
            <h4>Czy dostanę fakturę?</h4>
          </div>
          <div
            className={`${QuestionMain.answer} ${selectedQuestion === "Czy dostanę fakturę?"
              ? QuestionMain.show
              : ""
              }`}
          >
            <p>Tak. Jednak muszę to wiedzieć przed wystawieniem paragonu.</p>
          </div>
        </div>
        <div>
          <div
            className={QuestionMain.question}
            onClick={() =>
              handleQuestionClick(
                "Czy dostanę zaświadczenie do ubezpieczyciela/pracodawcy?"
              )
            }
          >
            {selectedQuestion ===
              "Czy dostanę zaświadczenie do ubezpieczyciela/pracodawcy?" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="16"
                width="14"
                viewBox="0 0 448 512"
              >
                <path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="16"
                width="14"
                viewBox="0 0 448 512"
              >
                <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
              </svg>
            )}
            <h4>Czy dostanę zaświadczenie do ubezpieczyciela/pracodawcy?</h4>
          </div>
          <div
            className={`${QuestionMain.answer} ${selectedQuestion ===
              "Czy dostanę zaświadczenie do ubezpieczyciela/pracodawcy?"
              ? QuestionMain.show
              : ""
              }`}
          >
            <p>Tak, wystawię zaświadczenia o przebytej rehabilitacji.</p>
          </div>
        </div>
        <div>
          <div
            className={QuestionMain.question}
            onClick={() => handleQuestionClick("Czy współpracuję z NFZ?")}
          >
            {selectedQuestion === "Czy współpracuję z NFZ?" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="16"
                width="14"
                viewBox="0 0 448 512"
              >
                <path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="16"
                width="14"
                viewBox="0 0 448 512"
              >
                <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
              </svg>
            )}
            <h4>Czy współpracuję z NFZ?</h4>
          </div>
          <div
            className={`${QuestionMain.answer} ${selectedQuestion === "Czy współpracuję z NFZ?"
              ? QuestionMain.show
              : ""
              }`}
          >
            <p>Nie współpracuję z NFZ.</p>
          </div>
        </div>
      </main>
    </>
  );
};
