import React from "react";
import { Main } from "./components/Main";
import { Helmet } from "react-helmet-async";

export const OfferSixth = () => {
  return (
    <>
      <Helmet>
        <title>Masaż - Fizjoterapeuta w Warszawie</title>
        <meta name="description" content="Dowiedz się więcej o masażu terapeutycznym. Oferujemy profesjonalne usługi masażu dostosowane do indywidualnych potrzeb klientów. Skontaktuj się z nami, aby umówić się na sesję masażu." />
        <link rel="canonical" href="https://www.fizjocezar.pl/masaż" />
      </Helmet>
      <Main />
    </>
  );
};
