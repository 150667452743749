import React from "react";
import { Main } from "./components/Main";
import { Helmet } from "react-helmet-async";

export const Contact = () => {
  return (
    <>
      <Helmet>
        <title>Kontakt - Fizjoterapeuta w Warszawie</title>
        <meta name="description" content="Skontaktuj się z nami, aby umówić wizytę lub uzyskać więcej informacji na temat naszych usług fizjoterapeutycznych. Jesteśmy do Twojej dyspozycji!" />
        <link rel="canonical" href="https://www.fizjocezar.pl/kontakt" />
      </Helmet>
      <Main />
    </>
  );
};
