import React from "react";
import { Main } from "./components/Main";
import { Helmet } from "react-helmet-async";

export const OfferFifth = () => {
  return (
    <>
      <Helmet>
        <title>Rehabilitacja Seniorów - Fizjoterapeuta w Warszawie</title>
        <meta name="description" content="Dowiedz się więcej o rehabilitacji seniorów. Oferujemy profesjonalne usługi rehabilitacyjne dostosowane do potrzeb osób starszych. Skontaktuj się z nami, aby rozpocząć proces rehabilitacji." />
        <link rel="canonical" href="https://www.fizjocezar.pl/rehabilitacja-seniorów" />
      </Helmet>
      <Main />
    </>
  );
};
