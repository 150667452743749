import React from "react";
import PriceListMain from "./../../../styles/layouts/PriceList/components/Main.module.css";
import { Link } from "react-router-dom";

export const Main = () => {
  return (
    <>
      <div className={PriceListMain.containerMain}>
        <div className={PriceListMain.aboutSection}>
          <h1>Cennik</h1>
        </div>
        <div className={PriceListMain.homeLink}>
          <Link to="/">Strona główna</Link>
          <Link to="/cennik">Cennik</Link>
        </div>
      </div>
      <main className={PriceListMain.gridContainer}>
        <div
          className={`${PriceListMain.visitInfo} ${PriceListMain.visitInfo1}`}
        >
          <h4>200 zł</h4>
          <p>
            <img src={require("./../../../images/PriceList/check-mark.png")} alt="fc" />
            Wizyta domowa
          </p>
        </div>
        <div
          className={`${PriceListMain.visitInfo} ${PriceListMain.visitInfo2}`}
        >
          <h4>350 zł</h4>
          <p>
            <img src={require("./../../../images/PriceList/check-mark.png")} alt="fc" />2
            pacjentów
          </p>
          <p>w jednej lokalizacji</p>
        </div>
        <div
          className={`${PriceListMain.visitInfo} ${PriceListMain.visitInfo3}`}
        >
          <h4>180 zł</h4>
          <p>
            <img src={require("./../../../images/PriceList/check-mark.png")} alt="fc" />
            Pakiet 10 wizyt
          </p>
          <p>
            <img src={require("./../../../images/PriceList/check-mark.png")} alt="fc" />
            1800 zł płatność z góry
          </p>
        </div>
        <div
          className={`${PriceListMain.additionalInfo} ${PriceListMain.additionalInfo1}`}
        >
          <h4>Zasada 24H</h4>
          <p>Prosimy odwoływać wizyty lub przekładać min. 24h przed wizytą</p>
        </div>
        <div
          className={`${PriceListMain.additionalInfo} ${PriceListMain.additionalInfo2}`}
        >
          <img src={require("./../../../images/PriceList/clock.png")} alt="fc" />
          <p>Czas trwania wizyty 60 min</p>
        </div>
      </main>
    </>
  );
};
