import React from "react";
import { Main } from "./components/Main";
import { Helmet } from "react-helmet-async";

export const Questions = () => {
  return (
    <>
      <Helmet>
        <title>FAQ - Fizjoterapeuta w Warszawie</title>
        <meta name="description" content="Najczęściej zadawane pytania dotyczące fizjoterapii. Znajdź odpowiedzi na pytania dotyczące zabiegów, terminów, i innych kwestii związanych z naszą praktyką." />
        <link rel="canonical" href="https://www.fizjocezar.pl/faq" />
      </Helmet>
      <Main />
    </>
  );
};
