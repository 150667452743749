import React, { useEffect, useState } from "react";
import AccountMain from "./../../../styles/layouts/Account/components/Main.module.css";
import getUserData from "./../../../utils/UserService";
import AppUserModle from "../../../models/AppUserModel";
import { Link } from "react-router-dom";

interface ChangePasswordRequest {
    currentPassword: string;
    newPassword: string;
    confirmationPassword: string;
}

interface ChangePasswordFormProps {
    onPasswordChangeSuccess: () => void;
    setShowErrorMessage: (value: boolean) => void;
    setShowSuccessMessage: (value: boolean) => void;
}

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({ onPasswordChangeSuccess, setShowErrorMessage, setShowSuccessMessage }) => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const token = localStorage.getItem("token");
    const [userData, setUserData] = useState<AppUserModle | null>(null);
    const [error, setError] = useState<string>("");

    const [formData, setFormData] = useState<ChangePasswordRequest>({
        currentPassword: '',
        newPassword: '',
        confirmationPassword: ''
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const validatePassword = (password: string): string | null => {
        if (password.length < 8) {
            return "Hasło musi zawierać co najmniej 8 znaków";
        }
        if (!/[A-Z]/.test(password)) {
            return "Hasło musi zawierać co najmniej jedną wielką literę";
        }
        if (!/[a-z]/.test(password)) {
            return "Hasło musi zawierać co najmniej jedną małą literę";
        }
        if (!/\d/.test(password)) {
            return "Hasło musi zawierać co najmniej jedną liczbę";
        }
        return null; // Hasło spełnia wszystkie wymagania
    };


    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const passwordError = validatePassword(formData.newPassword);
        if (passwordError) {
            setError(passwordError);
            setShowErrorMessage(true);
            setShowSuccessMessage(false);
            return;
        }
        try {
            const response = await fetch(
                `${apiUrl}/v1/user/changePassword`,
                // 'http://localhost:8080/api/v1/user/changePassword', 
                {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: "Bearer " + token,
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                console.log('Password changed successfully');
                onPasswordChangeSuccess();
                setShowSuccessMessage(true);
                setShowErrorMessage(false);
            } else {
                const errorMessage = await response.text();
                setError(errorMessage);
                setShowErrorMessage(true);
                setShowSuccessMessage(false);
                console.error("Failed to change password: ", errorMessage);
            }
        } catch (error) {
            console.error('Error occurred:', error);
        }
    };

    useEffect(() => {
        if (token) {
            getUserData(token).then((data) => {
                setUserData(data);
            });
        }
    }, []);

    return (
        <div className={AccountMain.updateProfile}>
            <form onSubmit={handleSubmit}>
                <div className={AccountMain.updateProfileRow}>
                    <div className={AccountMain.updateProfileCol}>
                        <label>Aktualne hasło</label>
                        <input
                            required
                            type="password"
                            name="currentPassword"
                            value={formData.currentPassword}
                            onChange={handleChange}
                        />
                    </div>
                    <div className={AccountMain.updateProfileCol}>
                        <label>Nowe hasło</label>
                        <input
                            required
                            type="password"
                            name="newPassword"
                            value={formData.newPassword}
                            onChange={handleChange}
                        />
                    </div>
                    <div className={AccountMain.updateProfileCol}>
                        <label>Powtórz nowe hasło</label>
                        <input
                            required
                            type="password"
                            name="confirmationPassword"
                            value={formData.confirmationPassword}
                            onChange={handleChange}
                        />
                    </div>
                    <div className={AccountMain.updateProfileCol}></div>
                    <div className={AccountMain.updateProfileCol}>
                        <Link to="">Zapomniałeś hasła?</Link>
                    </div>
                    <div className={AccountMain.updateProfileCol}></div>

                    <div className={AccountMain.submitBtn}>
                        <input type="submit" value="Zmień hasło" />
                    </div>
                    {error && (
                        <div className={AccountMain.alertProblem}>
                            <p>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="16"
                                    width="16"
                                    viewBox="0 0 512 512"
                                >
                                    <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" />
                                </svg>
                                {" "}{error}
                            </p>
                        </div>
                    )}
                </div>
            </form>
        </div>
    )
};

export default ChangePasswordForm;
