import React, { useEffect, useState } from "react";
import ContactMain from "./../../../styles/layouts/Contact/components/Main.module.css";
import { Link } from "react-router-dom";

interface AnonymousQuestionRequest {
  firstName: string;
  lastName: string;
  email: string;
  topic: string;
  question: string;
}

interface AuthenticatedQuestionRequest {
  topic: string;
  question: string;
}

export const Main = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [anonymousQuestionAsked, setAnonymousQuestionAsked] = useState(false);
  const [authenticatedQuestionAsked, setAuthenticatedQuestionAsked] =
    useState(false);
  const token = localStorage.getItem("token");
  const [anonymousFormData, setAnonymousFormData] =
    useState<AnonymousQuestionRequest>({
      firstName: "",
      lastName: "",
      email: "",
      topic: "",
      question: "",
    });

  const [authenticatedFormData, setAuthenticatedFormData] =
    useState<AuthenticatedQuestionRequest>({
      topic: "",
      question: "",
    });

  const askAnonymousQuestion = async (
    questionData: AnonymousQuestionRequest
  ) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(questionData),
    };

    try {
      const response = await fetch(
        `${apiUrl}/v1/anonymousQuestion/askQuestion`,
        // "http://localhost:8080/api/v1/anonymousQuestion/askQuestion",
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      setAnonymousQuestionAsked(true);
      const data = await response.json();
      return data;
    } catch (error) {
      console.log("There was an error!", error);
    }
  };

  const handleChangeAnnonymous = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setAnonymousFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmitAnnonymous = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    await askAnonymousQuestion(anonymousFormData);
  };

  const askAuthenticatedQuestion = async (
    questionData: AuthenticatedQuestionRequest
  ) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(questionData),
    };

    try {
      const response = await fetch(
        `${apiUrl}/v1/authenticatedQuestion/askQuestion`,
        // "http://localhost:8080/api/v1/authenticatedQuestion/askQuestion",
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      setAuthenticatedQuestionAsked(true);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Wystąpił błąd:", error);
    }
  };

  const handleChangeAuthenticated = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setAuthenticatedFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmitAuthenticated = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    await askAuthenticatedQuestion(authenticatedFormData);
  };

  async function checkSession() {
    if (!token) {
      setIsLoggedIn(false);
      return;
    }

    try {
      const response = await fetch(
        `${apiUrl}/v1/auth/checkSession`,
        // "http://localhost:8080/api/v1/auth/checkSession",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      if (response.ok) {
        setIsLoggedIn(true);
      } else if (response.status === 403) {
        setIsLoggedIn(false);
      } else {
        console.log("Nieznany błąd");
      }
    } catch (error) {
      console.error("Wystąpił błąd:", error);
    }
  }

  useEffect(() => {
    checkSession();
  }, []);

  return (
    <>
      <div className={ContactMain.containerMain}>
        <div className={ContactMain.aboutSection}>
          <h1>Kontakt</h1>
        </div>
        <div className={ContactMain.homeLink}>
          <Link to="/">Strona główna</Link>
          <Link to="/kontakt">Kontakt</Link>
        </div>
      </div>
      <main className={ContactMain.gridContainer}>
        <div className={ContactMain.info}>
          <div className={ContactMain.sectionTitle}>
            <h3>Skontaktuj się ze mną</h3>
            <span></span>
          </div>
        </div>

        {isLoggedIn ? (
          <div className={ContactMain.questionSection}>
            {authenticatedQuestionAsked ? (
              <p>
                Dziękujemy za zadane pytanie! Odpowiemy tak szybko jak to
                możliwe. Odpoweidź znajdziesz w zakładce zakładce{" "}
                <Link to="/konto/uzytkownik">konto, wiadomości</Link> oraz na
                emailu.
              </p>
            ) : (
              <form
                onSubmit={handleSubmitAuthenticated}
                className={ContactMain.contactForm}
              >
                <div className={ContactMain.questionHeading}>
                  <h2>Zadaj pytanie</h2>
                </div>
                <div className={ContactMain.row}>
                  <div className={ContactMain.formFields}>
                    <input
                      name="topic"
                      type="text"
                      placeholder="Temat"
                      onChange={handleChangeAuthenticated}
                      required
                    />
                  </div>
                </div>
                <div className={ContactMain.row}>
                  <div className={ContactMain.formFields}>
                    <textarea
                      name="question"
                      lang="pl"
                      placeholder="Twoja wiadomość"
                      onChange={handleChangeAuthenticated}
                      required
                    ></textarea>
                  </div>
                </div>
                <div className={ContactMain.row}>
                  <div className={ContactMain.formFields}>
                    <button type="submit">WYŚLIJ WIADMOŚĆ</button>
                  </div>
                </div>
              </form>
            )}
          </div>
        ) : (
          <div className={ContactMain.questionSection}>
            {anonymousQuestionAsked ? (
              <p>
                Dziękujemy za zadanie pytanie! Odpowiemy tak szybko jak to
                możliwe. Odpowiedź otrzymasz na email
              </p>
            ) : (
              <form
                onSubmit={handleSubmitAnnonymous}
                className={ContactMain.contactForm}
              >
                <div className={ContactMain.questionHeading}>
                  <h2>Zadaj pytanie</h2>
                </div>
                <div className={ContactMain.row}>
                  <div className={ContactMain.formFields}>
                    <input
                      type="text"
                      name="firstName"
                      placeholder="Imię"
                      onChange={handleChangeAnnonymous}
                      required
                    />
                  </div>
                  <div className={ContactMain.formFields}>
                    <input
                      type="text"
                      name="lastName"
                      placeholder="Nazwisko"
                      onChange={handleChangeAnnonymous}
                      required
                    />
                  </div>
                </div>
                <div className={ContactMain.row}>
                  <div className={ContactMain.formFields}>
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      onChange={handleChangeAnnonymous}
                      required
                    />
                  </div>
                  <div className={ContactMain.formFields}>
                    <input
                      type="text"
                      name="topic"
                      placeholder="Temat"
                      onChange={handleChangeAnnonymous}
                      required
                    />
                  </div>
                </div>
                <div className={ContactMain.row}>
                  <div className={ContactMain.formFields}>
                    <textarea
                      name="question"
                      lang="pl"
                      placeholder="Twoja wiadomość"
                      onChange={handleChangeAnnonymous}
                      required
                    ></textarea>
                  </div>
                </div>
                <div className={ContactMain.row}>
                  <div className={ContactMain.formFields}>
                    <button type="submit">WYŚLIJ WIADMOŚĆ</button>
                  </div>
                </div>
              </form>
            )}
          </div>
        )}
        <div className={ContactMain.contactDetails}>
          <div className={ContactMain.contactDetailContainer}>
            <h2>Kontakt</h2>
            <p>
              Jeśli masz jakiekolwiek pytania, skorzystaj z poniższych danych
              kontaktowych.
            </p>
            <div className={ContactMain.textHolder}>
              <h5>
                <img
                  src={require("../../../images/Contact/phone-solid-blue.png")}
                  alt="phone"
                />
                <span style={{ marginRight: '5px' }}>Numer telefonu: </span>
                518 414 650
              </h5>
            </div>
            <div className={ContactMain.textHolder}>
              <h5>
                <img
                  src={require("../../../images/Contact/envelope-solid-blue.png")}
                  alt="envelope"
                />
                <span style={{ marginRight: '5px' }}>{`Email: `}</span>
                info@fizjocezar.pl
              </h5>
            </div>
            <div className={ContactMain.socialLinks}>
              <a href="https://www.instagram.com/fizjo_domowki_bialoleka">
                <img src={require("../../../images/Contact/instagram.png")} alt="instagram" />
              </a>
              <a href="https://www.facebook.com/fizjocezar">
                <img src={require("../../../images/Contact/facebook.png")} alt="facebook" />
              </a>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
