import React from "react";
import { Main } from "./components/Main";
import { Helmet } from "react-helmet-async";

export const Book = () => {
  return (
    <>
      <Helmet>
        <title>Zarezerwuj - Fizjoterapeuta w Warszawie</title>
        <meta name="description" content="Zarezerwuj wizytę u naszego fizjoterapeuty w Warszawie. Skontaktuj się z nami, aby umówić termin wizyty i rozpocząć proces rehabilitacji." />
        <link rel="canonical" href="https://www.fizjocezar.pl/zarezerwuj" />
      </Helmet>
      <Main />
    </>
  );
};
