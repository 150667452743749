import React, { useEffect, useState } from "react";
import AccountMain from "./../../../../styles/layouts/Account/components/Main.module.css";

interface AuthenticatedQuestionDTO {
  topic: string;
  question: string;
  answer: string;
  questionTime: string;
  answerTime: string;
}

const MessagesComponent: React.FC = () => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [selectedMessage, setSelectedMessage] = useState<number | null>(null);
  const token = localStorage.getItem("token");
  const [isLoading, setIsLoading] = useState(true);
  const [questions, setQuestions] = useState<AuthenticatedQuestionDTO[]>([]);
  const [error, setError] = useState<string | null>(null);

  const getQuestions = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `${apiUrl}/v1/authenticatedQuestion/getQuestions`,
        // "http://localhost:8080/api/v1/authenticatedQuestion/getQuestions",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 204) {
        setQuestions([]);
      } else if (!response.ok) {
        throw new Error("Błąd podczas ładowania pytań");
      } else {
        const data = await response.json();
        console.log("data: ", data);
        setQuestions(data || []);
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : "Nieznany błąd");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSingleMessageClick = (id: number) => {
    setSelectedMessage(id);
  };

  function formatQuestionTime(answerTime: string): string {
    if (answerTime === null) {
      return "Oczekujące na odpowiedź";
    }

    const currentTime = new Date();
    const questionDate = new Date(answerTime);
    const differenceInSeconds = Math.floor((currentTime.getTime() - questionDate.getTime()) / 1000);
    const minute = 60;
    const hour = minute * 60;
    const day = hour * 24;
    const month = day * 30;
    const year = day * 365;

    
    if (differenceInSeconds < hour) {
      return `${Math.floor(differenceInSeconds / minute)} minut temu`;
    } else if (differenceInSeconds < day) {
      return `${Math.floor(differenceInSeconds / hour)} godzin temu`;
    } else if (differenceInSeconds < month) {
      return `${Math.floor(differenceInSeconds / day)} dni temu`;
    } else if (differenceInSeconds < year) {
      return `${Math.floor(differenceInSeconds / month)} miesięcy temu`;
    } else {
      return `${Math.floor(differenceInSeconds / year)} lat temu`;
    }
  }

  useEffect(() => {
    getQuestions();
  }, []);

  return (
    <div className={AccountMain.messages}>
      <div className={AccountMain.messagesContainer}>
        <div className={AccountMain.messagesListContainer}>
          <div className={AccountMain.messagesHeader}>
            <h5>Skrzynka odbiorcza</h5>
          </div>
          <div className={AccountMain.messagesList}>
            {questions.map((question, index) => (
              <div
                key={index}
                className={`${AccountMain.singleMessage} ${
                  selectedMessage === index ? AccountMain.selectedMessage : ""
                }`}
                onClick={() => handleSingleMessageClick(index)}
              >
                <p>{question.topic}</p>
                <p>{formatQuestionTime(question.answerTime)}</p>
              </div>
            ))}
          </div>
        </div>
        <div className={AccountMain.messagesContent}>
          {selectedMessage === null ||
          questions.length === 0 ||
          selectedMessage >= questions.length ? (
            <div className={AccountMain.noMessage}>
              <p>Nie wybrano wiadomości</p>
            </div>
          ) : (
            <div className={AccountMain.answerMessage}>
              <div className={AccountMain.answerTitleName}>
                <p>{questions[selectedMessage]?.topic ?? "Brak tematu"}</p>
              </div>
              <div className={AccountMain.answerContent}>
                <div className={AccountMain.question}>
                  {questions[selectedMessage]?.question ?? "Brak pytania"}
                </div>
                <div className={AccountMain.answer}>
                  <p>{questions[selectedMessage].answer}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MessagesComponent;
