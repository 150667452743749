import React, { useEffect, useState } from "react";
import AccountMain from "./../../../../styles/layouts/Account/components/Main.module.css";
import { Link } from "react-router-dom";
import AppUserModle from "../../../../models/AppUserModel";
import getUserData from "../../../../utils/UserService";
import ScheduledBookings from "../ScheduledBookings";
import CompletedAndCanceledBookings from "../CompletedAndCanceledBookings";
import MessagesComponent from "./MessagesComponent";
import ChangePasswordForm from "../ChangePasswordForm";

export const Main = () => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [displayedSection, setDisplayedSection] = useState<string | null>(null);
  const [activLink, setActivLink] = useState("");
  const [selectedMessage, setSelectedMessage] = useState<number | null>(null);
  const [userData, setUserData] = useState<AppUserModle | null>(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const token = localStorage.getItem("token");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserData((prevUserData) => {
      // Sprawdź, czy pole należy do zagnieżdżonego obiektu, na przykład "address.city"
      if (name.includes(".")) {
        const [parentKey, childKey] = name.split(".");
        return {
          ...prevUserData,
          [parentKey]: {
            // Zachowaj istniejące zagnieżdżone wartości
            ...(prevUserData as any)[parentKey],
            [childKey]: value,
          },
        };
      }
      // Dla niezagnieżdżonych właściwości, zachowaj dotychczasową logikę
      return {
        ...prevUserData,
        [name]: value,
      };
    });
  };

  const handleNavLinkClick = (sectionName: string) => {
    setDisplayedSection(sectionName);
    setActivLink(sectionName);
  };

  const handlePasswordChangeSuccess = () => {
    handleNavLinkClick('update-profile');
    setShowSuccessMessage(true);
    setShowErrorMessage(false);
  };

  const handleSingleMessageClick = (index: number) => {
    setSelectedMessage(index);
  };

  const changeUserData = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!userData) return;

    // Przygotowanie danych do wysłania, bazując na aktualnym stanie userData
    const dataToSend = {
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      phoneNumber: userData.phoneNumber,
      city: userData.address?.city || "",
      street: userData.address?.street || "",
      buildingNumber: userData.address?.buildingNumber || "",
      flatNumber: userData.address?.flatNumber || "",
    };

    const requestBody = JSON.stringify(dataToSend); // Przygotowanie ciała żądania do logowania
    console.log(
      "Wysyłanie requestu do /api/v1/user/changeData z danymi:",
      requestBody
    );

    try {
      const response = await fetch(
        `${apiUrl}/v1/user/changeData`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: requestBody,
        }
      );

      if (response.ok) {
        setShowSuccessMessage(true);
        setShowErrorMessage(false);
      } else {
        setShowSuccessMessage(false);
        setShowErrorMessage(true);
      }
    } catch (error) {
      console.error("Wystąpił błąd:", error);
      alert("Wystąpił błąd podczas aktualizacji danych profilu");
    }
  };

  useEffect(() => {
    if (token) {
      getUserData(token).then((data) => {
        setUserData(data);
      });
    }
  }, []);

  return (
    <>
      <div className={AccountMain.containerMain}>
        <div className={AccountMain.aboutSection}>
          <h1>Konto</h1>
        </div>
        <div className={AccountMain.homeLink}>
          <Link to="/">Strona główna</Link>
          <Link to="/konto">Konto</Link>
        </div>
      </div>
      <main>
        <section className={AccountMain.sectionContainer}>
          <div className={AccountMain.myAccount}>
            <h3>Witaj z powrotem {userData?.firstName}</h3>
            <ul className={AccountMain.myAccountNav}>
              <li>
                <a
                  className={
                    activLink === "next-reservation" ? AccountMain.active : ""
                  }
                  onClick={() => handleNavLinkClick("next-reservation")}
                >
                  Następna Rezerwacja
                </a>
              </li>
              <li>
                <a
                  className={
                    activLink === "reservation-history"
                      ? AccountMain.active
                      : ""
                  }
                  onClick={() => handleNavLinkClick("reservation-history")}
                >
                  Historia Rezerwacji
                </a>
              </li>
              <li>
                <a
                  className={
                    activLink === "update-profile" ? AccountMain.active : ""
                  }
                  onClick={() => handleNavLinkClick("update-profile")}
                >
                  Zaktualizuj swój profil
                </a>
              </li>
              <li>
                <a
                  className={activLink === "messages" ? AccountMain.active : ""}
                  onClick={() => handleNavLinkClick("messages")}
                >
                  Wiadomości
                </a>
              </li>
            </ul>
            {displayedSection === "next-reservation" && (
              <div>
                <ScheduledBookings userId={userData?.id} />
              </div>
            )}

            {displayedSection === "reservation-history" && (
              <div>
                <CompletedAndCanceledBookings userId={userData?.id} />
              </div>
            )}

            {displayedSection === "update-profile" && (
              <div className={AccountMain.updateProfile}>
                <form onSubmit={changeUserData}>
                  <div className={AccountMain.updateProfileRow}>
                    <div className={AccountMain.updateProfileCol}>
                      <label>Imię</label>
                      <input
                        onChange={handleInputChange}
                        type="text"
                        name="firstName"
                        value={userData ? userData.firstName : ""}
                      />
                    </div>
                    <div className={AccountMain.updateProfileCol}>
                      <label>Nazwisko</label>
                      <input
                        onChange={handleInputChange}
                        type="text"
                        name="lastName"
                        value={userData ? userData.lastName : ""}
                      />
                    </div>
                    <div className={AccountMain.updateProfileCol}>
                      <label>Numer telefonu</label>
                      <input
                        onChange={handleInputChange}
                        type="text"
                        name="phoneNumber"
                        value={userData ? userData.phoneNumber : ""}
                      />
                    </div>
                    <div className={AccountMain.updateProfileCol}>
                      <label>Miasto</label>
                      <input
                        onChange={handleInputChange}
                        type="text"
                        name="address.city"
                        value={userData ? userData.address?.city : ""}
                      />
                    </div>
                    <div className={AccountMain.updateProfileCol}>
                      <label>Ulica</label>
                      <input
                        onChange={handleInputChange}
                        type="text"
                        name="address.street"
                        value={userData ? userData.address?.street : ""}
                      />
                    </div>
                    <div className={AccountMain.updateProfileCol}>
                      <label>Numer domu/bloku</label>
                      <input
                        onChange={handleInputChange}
                        type="text"
                        name="address.buildingNumber"
                        value={userData ? userData.address?.buildingNumber : ""}
                      />
                    </div>
                    <div className={AccountMain.updateProfileCol}>
                      <label>Number miszkania</label>
                      <input
                        onChange={handleInputChange}
                        type="text"
                        name="address.flatNumber"
                        value={userData ? userData.address?.flatNumber : ""}
                      />
                    </div>
                    <div className={AccountMain.updateProfileCol}></div>
                    <div className={AccountMain.submitBtn}>
                      <input type="submit" value="Zaktualizuj dane" />
                    </div>
                    <div className={AccountMain.submitBtn}>
                      <button
                        type="button"
                        onClick={() => handleNavLinkClick("change-password")}>
                        Zmień hasło
                      </button>
                    </div>
                    {showErrorMessage && (
                      <div className={AccountMain.alertProblem}>
                        <p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="16"
                            width="16"
                            viewBox="0 0 512 512"
                          >
                            <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" />
                          </svg>
                          Nie udało się zaktualicować
                        </p>
                      </div>
                    )}
                    {showSuccessMessage && (
                      <div className={AccountMain.alertSuccess}>
                        <p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="16"
                            width="16"
                            viewBox="0 0 512 512"
                          >
                            <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                          </svg>
                          Zaktualizowano pomyślnie
                        </p>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            )}

            {displayedSection === "change-password" && (
              <ChangePasswordForm
                onPasswordChangeSuccess={handlePasswordChangeSuccess}
                setShowErrorMessage={setShowErrorMessage}
                setShowSuccessMessage={setShowSuccessMessage}
              />
            )}

            {displayedSection === "messages" && (
              <MessagesComponent />
            )}
          </div>
        </section>
      </main>
    </>
  );
};
