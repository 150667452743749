import React from "react";
import PhysiotherapyWarsawSectionCSS from "./../../../styles/layouts/HomePage/components/PhysiotherapyWarsawSection.module.css";

export const PhysiotherapyWarsawSection = () => {
  return (
    <section
      id="fizjoterapia-warszawa"
      className={PhysiotherapyWarsawSectionCSS.physiotherapyWarsawSection}
    >
      <div className={PhysiotherapyWarsawSectionCSS.physiotherapyWarsaw}>
        <div
          className={PhysiotherapyWarsawSectionCSS.physiotherapyWarsawContent}
        >
          <div className={PhysiotherapyWarsawSectionCSS.sectionTitle}>
            <h1>Fizjoterapia na terenie Warszawy</h1>
            <span></span>
          </div>
          <p>
            FizjoCezar to usługi fizjoterapeutyczne dostępne w Warszawie, gdzie przyjeżdżam prosto pod Twój dach.
            To nie tylko wygoda, ale kompleksowa terapia w komfortowych i bezpiecznych warunkach domowych.
          </p>
          <p>
            Marzysz o skutecznej rehabilitacji bez straty czasu na dojazdy?
            Rehabilitacja domowa eliminuje konieczność przemieszczania się do gabinetu fizjoterapeutycznego, oszczędzając Twój cenny czas.
            Teraz możesz skupić się wyłącznie na poprawie zdrowia, nie martwiąc się o dojazd czy organizację transportu po operacji.
          </p>
        </div>
        <div className={PhysiotherapyWarsawSectionCSS.physiotherapyWarsawLogo}>
          <img
            src={require("./../../../images/HomePage/PhysiotherapyWarsawSection/logoNapisNiebieskiPion.png")}
            alt="logo"
          />
        </div>
      </div>
    </section>
  );
};
