import React, { useState } from "react";

import LoginCSS from "../../styles/layouts/NavBarAndFooter/Login.module.css";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

interface LoginPopupProps {
  toggleVisibility: () => void;
  toggleLoginPopup: () => void;
}

const LoginPopup: React.FC<LoginPopupProps> = ({
  toggleVisibility,
  toggleLoginPopup,
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [errorMessage, setErrorMessage] = useState("");
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const [displayedSection, setDisplayedSection] = useState<string | null>(null);
  const [email, setEmail] = useState<string>("");
  const [otp, setOTP] = useState<number | undefined>();
  const [newPassword, setNewPassword] = useState('');
  const [confirmationPassword, setConfirmationPassword] = useState('');
  const [passwordChanged, setPasswordChanged] = useState(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [timer, setTimer] = useState(120);

  const startTimer = () => {
    setTimer(120);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value,
    });
  };

  const saveToken = (token: string) => {
    const now = new Date();
    localStorage.setItem("token", token);
    setTimeout(() => {
      localStorage.removeItem("token");
      localStorage.removeItem("userRole");
      // Tutaj można dodać dodatkowe działania, np. wylogowanie użytkownika
    }, 2 * 3600 * 1000);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const response = await fetch(
        `${apiUrl}/v1/auth/authenticate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(loginData),
        }
      );
      const data = await response.json();
      if (response.ok) {
        saveToken(data.token);
        window.location.reload();
      } else {
        setErrorMessage("Nieprawidłowy email lub hasło.");
      }
    } catch (error) {
      setErrorMessage("Nieprawidłowy email lub hasło.");
    }
  };

  const handleVerifyEmail = async () => {
    try {
      setIsLoading(true);

      const response = await fetch(
        `${apiUrl}/v1/forgotPassword/verifyEmail/${email}`,
        {
          method: 'POST',
        });

      console.log(email);

      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(errorMessage || 'Network response was not ok.');
      }

      setErrorMessage("");
      setDisplayedSection("verify-otp");
      console.log('E-mail został pomyślnie zweryfikowany');
    } catch (error) {
      setErrorMessage(`${error}`);
    } finally {
      setIsLoading(false);
      startTimer();
    }
  };

  const handleVerifyOTP = async () => {
    try {
      setIsLoading(true);

      const response = await fetch(
        `${apiUrl}/v1/forgotPassword/verifyOTP/${otp}/${email}`,
        {
          method: 'POST',
        });

      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(errorMessage || "Netwoek response was not ok.");
      }

      setDisplayedSection("reset-password");
      setErrorMessage("");
      console.log('Weryfikacja udana');

    } catch (error) {
      setErrorMessage(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetPassword = async () => {
    try {
      setIsLoading(true);

      if (
        newPassword.length < 8 ||
        !/[A-Z]/.test(newPassword) ||
        !/[a-z]/.test(newPassword) ||
        !/\d/.test(newPassword)
      ) {
        throw new Error('Hasło musi zawierać co najmniej 8 znaków, jedną wielką literę, jedną małą literę oraz jedną liczbę.');
      }

      const response = await fetch(
        `${apiUrl}/v1/forgotPassword/changePassword/${email}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            newPassword,
            confirmationPassword,
          }),
        });

      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(errorMessage || 'Network response was not ok.');
      }

      setEmail("");
      setPasswordChanged(true);
      setDisplayedSection("login")
      console.log('Password changed successfully!');
    } catch (error) {
      setErrorMessage(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDisplayedSection = (sectionName: string) => {
    setErrorMessage("");
    setDisplayedSection(sectionName);
  }

  useEffect(() => {
    setDisplayedSection("login");
  }, []);

  useEffect(() => {
    const countdown = setTimeout(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      } else {
        // Obsługa przypadku gdy licznik osiągnie zero
      }
    }, 1000);

    // Czyszczenie timera po odmontowaniu komponentu
    return () => clearTimeout(countdown);
  }, [timer]);

  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;

  return (
    <div className={LoginCSS.loginPopup}>
      <div className={LoginCSS.overlay}></div>

      <div className={LoginCSS.popupContent}>
        <div className={LoginCSS.loginBox}>
          <div className={LoginCSS.LoginBoxTextContent}>
            <h2>Zarejestruj się</h2>
            <p>Kliknij przycisk Zarejestruj się, aby utworzyć nowe konto.</p>
            <button onClick={toggleVisibility}>Zarejestruj się</button>
          </div>
        </div>

        {displayedSection === "login" && (
          <div className={LoginCSS.formContainer}>
            <h2>Zaloguj się na konto</h2>
            <form onSubmit={handleSubmit} className={LoginCSS.loginForm}>
              <div className={LoginCSS.loginFormValues}>
                <input
                  type="text"
                  name="email"
                  placeholder="Email"
                  value={loginData.email}
                  onChange={handleInputChange}
                  className={LoginCSS.normalInput}
                />
                <input
                  type="password"
                  name="password"
                  placeholder="Hasło"
                  value={loginData.password}
                  onChange={handleInputChange}
                  className={LoginCSS.normalInput}
                />
              </div>
              <a onClick={() => handleDisplayedSection("verify-Email")} >Zapomniałeś hasła?</a>
              {errorMessage && (
                <p className={LoginCSS.errorMessage}>{errorMessage}</p>
              )}
              {passwordChanged && (
                <p className={LoginCSS.successMessage}>Hasło zostało pomyślnie zmienione!</p>
              )}
              <button type="submit">Zaloguj</button>
            </form>
          </div>
        )}

        {displayedSection === "verify-Email" && (
          <div className={LoginCSS.formContainer}>
            <h2>Zmiana hasła</h2>
            <div className={LoginCSS.loginForm}>
              <div className={LoginCSS.loginFormValues}>
                <p>Na podany adres e-mail wyślemy kod do zmiany hasła.</p>
                <input
                  type="text"
                  placeholder="Twój adres e-mail"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className={LoginCSS.normalInput}
                />
              </div>
              {errorMessage && (
                <p className={LoginCSS.errorMessage}>{errorMessage}</p>
              )}
              <button onClick={handleVerifyEmail}>
                {isLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Wyślij kod'}
              </button>
            </div>
          </div>
        )}

        {displayedSection === "verify-otp" && (
          <div className={LoginCSS.formContainer}>
            <h2>Zmiana hasła</h2>
            <div className={LoginCSS.loginForm}>
              <div className={LoginCSS.loginFormValues}>
                <p>Do zakończenia ważności kodu pozostało: {minutes}:{seconds < 10 ? `0${seconds}` : seconds}</p>
                <input
                  type="number"
                  placeholder="Podaj kod"
                  value={otp}
                  onChange={(e) => setOTP(parseInt(e.target.value))}
                  className={LoginCSS.normalInput}
                />
              </div>
              {errorMessage && (
                <p className={LoginCSS.errorMessage}>{errorMessage}</p>
              )}
              {timer === 0 ? (
                <button onClick={handleVerifyEmail}>
                  {isLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Wyślij ponownie kod"}
                </button>
              ) : (
                <button onClick={handleVerifyOTP}>
                  {isLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Zmień hasło"}
                </button>
              )}
            </div>
          </div>
        )}

        {displayedSection === "reset-password" && (
          <div className={LoginCSS.formContainer}>
            <h2>Zmiana hasła</h2>
            <div className={LoginCSS.loginForm}>
              <div className={LoginCSS.loginFormValues}>
                <input
                  type="password"
                  placeholder="Nowe hasło"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className={LoginCSS.normalInput}
                />
                <input
                  type="password"
                  placeholder="Powtórz nowe hasło"
                  value={confirmationPassword}
                  onChange={(e) => setConfirmationPassword(e.target.value)}
                  className={LoginCSS.normalInput}
                />
              </div>
              {errorMessage && (
                <p className={LoginCSS.errorMessage}>{errorMessage}</p>
              )}
              <button onClick={handleResetPassword}>
                {isLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Zmień hasło"}
              </button>
            </div>
          </div>
        )}

        <button className={LoginCSS.closeBtn} onClick={toggleLoginPopup}>
          <span>x</span>
        </button>
      </div>
    </div>
  );
};

export default LoginPopup;
