import React from "react";
import { InformationsSection } from "./components/InformationsSection";
import { PhysiotherapyWarsawSection } from "./components/PhysiotherapyWarsawSection";
import { RehabBenefitsSection } from "./components/RehabBenefitsSection";
import { OurService } from "./components/OurServices";
import { OpinionsSection } from "./components/OpinionsSection";
import img14 from "./../../images/HomePage/ImageSection/Zdjęcie14.jpg";
import img15 from "./../../images/HomePage/ImageSection/Zdjęcie15.jpg";
import img16 from "./../../images/HomePage/ImageSection/Zdjęcie16.jpg";
import img17 from "./../../images/HomePage/ImageSection/Zdjęcie17.jpg";
import img2 from "./../../images/HomePage/ImageSection/Zdjęcie2.jpg";
import { ImageSlider } from "./components/ImageSlider";
import { Helmet } from "react-helmet-async";



export const HomePage = () => {
  const IMAGES = [
    { url: img14, alt: "Pacjent na terapii manualnej" },
    { url: img15, alt: "Fizjoterapeuta w trakcie zabiegu" },
    { url: img16, alt: "Ćwiczenia rehabilitacyjne" },
    { url: img17, alt: "Fizjoterapeuta w trakcie wywiadu" },
    { url: img2, alt: "Fizjoterapeuta" },
  ]

  return (
    <>
      <Helmet>
        <title>Strona główna - Fizjoterapeuta w Warszawie</title>
        <meta name="description" content="Fizjoterapia na wyciągnięcie ręki Dojedziemy do Ciebie! Fizjoterapia na całym terenie Warszawy. Dlaczego rehabilitacja domowa? TERAPIA MANUALNA WG KONCEPCJI MAITLANDA" />
        <link rel="canonical" href="https://www.fizjocezar.pl/" />
      </Helmet>
      <ImageSlider images={IMAGES} />
      {/* <ImageSection /> */}
      <InformationsSection />
      <PhysiotherapyWarsawSection />
      <RehabBenefitsSection />
      <OurService />
      <OpinionsSection />
    </>
  );
};
