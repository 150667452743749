import React from "react";
import { Main } from "./components/Main";
import { Helmet } from "react-helmet-async";

export const OfferFirst = () => {
  return (
    <>
      <Helmet>
        <title>Terapia Manualna wg Koncepcji Maitlanda - Fizjoterapeuta w Warszawie</title>
        <meta name="description" content="Dowiedz się więcej o terapii manualnej wg koncepcji Maitlanda. Sprawdź, jak może Ci pomóc w leczeniu różnych dolegliwości i poprawie funkcjonowania układu ruchu." />
        <link rel="canonical" href="https://www.fizjocezar.pl/terapia-manualna-wg-koncepcji-maitlanda" />
      </Helmet>
      <Main />
    </>
  );
};
